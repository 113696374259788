<template>
  <div>
    <!-- pic8A8C904D-2CE7-4653-8E5B-D8B049D7D7D5.jpg -->
    <img class="img-large rounded" src="@/assets/news-network/lu-preorder.jpg">
    <p>
      LEGO® Universe pre-ordering is now open!
    </p>
    <p>
      Fans who pre-order the game will get a FREE limited edition LEGO Universe minifigure! In addition to the collectible minifigure, we're offering a bonus code for an exclusive in-game accessory!
    </p>
    <p>
      These special gifts are available only until the Live One launch of LEGO Universe-- they're our way of saying "Thanks!" to fans for pre-ordering the game!
    </p>
    <p>
      Find out how to pre-order LEGO Universe
      <router-link :to="{ name: 'preorder' }">here</router-link>
    </p>
  </div>
</template>
